
export default function Order_completed(){

    return(  
            <>
                <p>Payment was successful</p>
                <p>Thank you for your order</p>
                <p>All information about your order with a receipt was sent to your email</p>
            </> 
    )
}